/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import sparkDirective from "../common/directives/spark-directive";

const MODULE_NAME = 'line-item-diag-controller';

import resources from '../../components/api/resources/resources';

angular.module(MODULE_NAME, [uiBootstrap, translate, resources, sparkDirective])

  .controller('LineItemDiagnosticsController', function($scope, $interval, $interpolate, model, LocalUserPermissions, Bids, DiagnosticRequestExecution) {
    const ctrl = this,
      intervalRepeat = 40;
    let request,
      intervalPromise,
      intervalCounter,
      intervalTime = 1250,
      initData = function() {
        ctrl.results = {messages: []};
        intervalCounter = 0;
      },
      checkComplete = function() {
        DiagnosticRequestExecution.get({id: request.id}).$promise.then(function(results) {
          results.results.forEach(function(result) {
            result.message.apiTranslation = $interpolate(result.message.text)(result.message.parameters);
          });
          intervalCounter++;
          if (results.isCompleted()) {
            ctrl.results = results;
            $interval.cancel(intervalPromise);
            ctrl.launchDisabled = false;
          } else if (!results.inProgress() || intervalCounter >= intervalRepeat) {
            ctrl.results = results;
            ctrl.results.failed = true;
            $interval.cancel(intervalPromise);
            ctrl.launchDisabled = false;
          } else {
            ctrl.results = results;
          }
        });
      };

    ctrl.launchDisabled = false;

    ctrl.showBidUpdates = model.bidSpecification.bidStrategy === 'DYNAMIC';
    ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
    ctrl.model = model;
    ctrl.infoReset = {};

    ctrl.getFloorPrices = function() {
      ctrl.infoReset.info();
    };

    ctrl.hasBidResults = false;
    ctrl.getBidUpdates = function() {
      ctrl.bidProcessing = true;
      Bids.bidUpdates(model.id).then(function(data) {
        ctrl.hasBidResults = true;
        ctrl.bidProcessing = false;
        ctrl.bidsBySite = data;
      });
    };

    initData();
    ctrl.launchRequest = function() {
      initData();
      ctrl.launchDisabled = true;
      DiagnosticRequestExecution.start({
        testType: 'LINE_ITEM',
        lineItem: model.id
      }).then(function(result) {
        request = result;
        intervalPromise = $interval(checkComplete, intervalTime, intervalRepeat);
        intervalTime = 5000;
      });
    };

    $scope.$on('$destroy', function() {
      $interval.cancel(intervalPromise);
    });
  });

export default MODULE_NAME;